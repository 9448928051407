import React, { useRef } from "react";
import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/pagination";
import "swiper/css/navigation";

import { FreeMode, Pagination, Navigation } from "swiper/modules";
// import axios from "axios";
// import { useQuery } from "@tanstack/react-query";
import SectionTitle from "../ui/Section-title";
import LightLabel from "../ui/LightLabel";
// import { BaseUrl } from "../../api/BaseUrl";
import { img4 } from "../../assets/svg/Distubution";
import { onlinecontent } from "../../api/BaseUrl";
import { Link } from "react-router-dom";

export default function Partners({ ar , data }) {
  const swiperRef = useRef(null);

  // async function getPartner() {
  //   const res = await axios.get(`${BaseUrl}api/v1/partners`);
  //   return res.data.content;
  // }

  // const { data } = useQuery({
  //   queryKey: ["get-partner"],
  //   queryFn: getPartner,
  // });


  return (
    <section id="Partners " className="mt-20     ">
      <div className="w-full  flex justify-center items-center flex-col gap-4">
        <LightLabel title={ar ? "الشركاء" : "Partners"} />
        <SectionTitle title={ar ? "الشركاء لدينا" : "Our Partners"} />
      </div>
      <div className="relative mt-4">
        <Swiper
          onSwiper={(swiper) => {
            swiperRef.current = swiper;
          }}
          freeMode={true}
          loop={true}
          modules={[FreeMode, Pagination, Navigation]}
          className="mySwiper w-10"
          breakpoints={{
            640: {
              slidesPerView: 2,
              spaceBetween: 20,
            },
            // when window width is >= 768px
            768: {
              slidesPerView: 3,
              spaceBetween: 30,
            },
            // when window width is >= 1024px
            1250: {
              slidesPerView: 4,
              spaceBetween: 30,
            },
          }}
        >
          {data?.map((item) => (
            <SwiperSlide className="flex flex-col">
        <img src={`${onlinecontent}/assets/img/${item.image}`} alt="" />
        <p className="text-center MediumAr font-bold mt-3 text-lg text-[#5c5c5c]">
        {item.name}
              </p>
            </SwiperSlide>
          ))}
        </Swiper>
        <button
          className="absolute top-1/2 z-10 left-6 transform -translate-y-1/2 hover:bg-yellow-120 bg-[#091242] text-white px-[14px] py-4 rounded-full"
          onClick={() => swiperRef.current?.slideNext()}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="15"
            height="12"
            viewBox="0 0 15 12"
            fill="none"
          >
            <path
              d="M13.5352 6H1.53516M1.53516 6L6.53516 1M1.53516 6L6.53516 11"
              stroke="white"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </button>
        <button
          className="absolute top-1/2 z-10 right-6 -rotate-180 transform -translate-y-1/2 hover:bg-yellow-120 bg-[#091242] text-white px-[14px] py-4 rounded-full"
          onClick={() => swiperRef.current?.slidePrev()}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="15"
            height="12"
            viewBox="0 0 15 12"
            fill="none"
          >
            <path
              d="M13.5352 6H1.53516M1.53516 6L6.53516 1M1.53516 6L6.53516 11"
              stroke="white"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </button>
      </div>
      <Link to="/partners" >
         <div className="w-full flex justify-center items-center mt-10 flex-col gap-4">
        <button className="Regular MediumAr mt-2 bg-blue-120  w-fit px-8 py-2 text-white rounded-[0px]">
          {ar ? "عرض جميع الشركاء" : "Show All Partners"}
        </button>
      </div>
      </Link>
   
    </section>
  );
}
