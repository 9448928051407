import SectionTitle from "../ui/Section-title";
import LightLabel from "../ui/LightLabel";
import Paragraph from "../ui/Paragraph";

import workerImg from "../../assets/about-page/Photo.png";
import DarkLabel from "../ui/DarkLabel";

export default function OurVision({ar , ourvision , whoisus ,whoisus2 , whoisus3 , whoisus4}) {
  console.log(ourvision);
  return (
    <>
      <section className={`bg-gray-20 flex justify-end w-full  overflow-hidden md:py-10 lg:py-0 ${ar? 'lg:pr-10 ' :'lg:pl-10'}`}>
        <div className=" w-[95%]   mt-24 flex  items-start flex-col  lg:flex-row ">
          <div className="flex  w-[min(800px,_100%)] flex-col gap-4 items-center lg:items-start lg:px-24  pb-14 text-center lg:text-start ">
            <LightLabel title={ar? 'من نحن' :"About us"} />
            <SectionTitle title={ar? 'بعد عام 2003 ' :"After 2003"} />
            <Paragraph className='max-w-full ' p={ar? whoisus :"Leverage agile frameworks to provide a robust synopsis for strategy foster collaborative thinking to further the overall value proposition."} />

            <Paragraph className='max-w-full'  p={ar? whoisus2 :"Sed ut perspiciatis, unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam eaque ipsa, quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt, explicabo. Nemo enim ipsam voluptatem quia voluptassit."} />
            <Paragraph className='max-w-full' p={ar? whoisus3 :"Sed ut perspiciatis, unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam eaque ipsa, quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt, explicabo. Nemo enim ipsam voluptatem quia voluptassit.Sed ut perspiciatis, unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam eaque ipsa, quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt, explicabo. Nemo enim ipsam voluptatem quia voluptassit"} />
            <Paragraph className='max-w-full' p={ar? whoisus4  :"In 2017, the company continued its development plan by exploring innovations aimed at solving challenges. This led to the creation of dual-layered accessories known for their protection, durability, and flexibility capabilities. The company obtained a series of patents for its PVC PRO product. By 2022, the product was launched for the first time in the Iraqi market and subsequently expanded to global markets. Al-Baraka Industrial Investments Company remains the sole owner worldwide of the product rights and continues to export it to several countries."} />
         
          </div>
          {/* <div className=" md:h-[650px] w-full  lg:w-[50%] aboutimg "> */}

          <div className="  w-full  lg:w-[800px] aboutimg ">
          <img src={workerImg} alt="" />
          </div>
        </div>
      </section>
      <section className="bg-yellow-120 pattern-image flex justify-center">
        <div className="container flex flex-col gap-8 items-center justify-center min-h-[50vh]">
          <DarkLabel title={ar? 'الرؤية' :"our vision"} />
          <h2 className="text-3xl leading-10 	 bold text-blue-110 text-center sm:text-2xl md:text-3xl lg:text-4xl xl:text-5xl">
            {/* {ar? 'ترسيخ الحضور محلياً، والانطلاقـ نحو الأسواقـ العالمية' :'Establishing presence locally, and launch towards markets globalism'} */}
            {ar? ourvision :'Establishing presence locally, and launch towards markets globalism'}

          </h2>
        </div>
      </section>
    </>
  );
}
