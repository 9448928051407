import React, { useContext } from 'react'
import Landing from './Landing'
import Products from '../../components/Home/Products'
import LanguageContext from '../../LanguageContext';
import WhatsIcon from '../../components/ui/WhatsIcon';

const ProductsPage = () => {
  const {  ar,  rtl } =
  useContext(LanguageContext);
  return (
    <div className={rtl}>
    <Landing ar={ar} />
        <Products/>
        <WhatsIcon  />

    </div>
  )
}

export default ProductsPage