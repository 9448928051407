
import Distributors from "../../Globals/Distributors";
import { useContext } from "react";
import Congrat from "../../Globals/Congrat";
import Landing from "./Landing";
import LanguageContext from "../../../LanguageContext";
import Single from "./Single";
export default function SingleMedia() {
  const {  ar, rtl } =
    useContext(LanguageContext);
  return (
    <div className={rtl}>
    <Landing ar={ar}/>
    <Single  ar={ar}/>
      <Congrat ar={ar} className='mt-24' />
      <Distributors ar={ar} />
    </div>
  );
}
