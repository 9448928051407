import React, { useContext } from "react";
import Congrat from "../Globals/Congrat";
import Distributors from "./Distributors.jsx";
import MainDistributors from "./MainDistributors.jsx";
import LanguageContext from "../../LanguageContext.js";
import WhatsIcon from "../ui/WhatsIcon.jsx";
import axios from "axios";
import { onlineApi } from "../../api/BaseUrl.jsx";
import { useQuery } from "@tanstack/react-query";

export default function OurDistributors() {

  const {  ar,  rtl } =
    useContext(LanguageContext);

    async function getHomepagedatas() {
      const res = await axios.get(`${onlineApi}homepagedata`);
      console.log(res.data);
      return res.data;
    }
  
    const { data, isLoading, error} = useQuery({
      queryKey: ["get-data-Homepage"],
      queryFn: getHomepagedatas,
    });
    const convertData =data && data[0];
    if (isLoading) {
      return <div>Loading...</div>;
    }
  
    if (error) {
      return <div>Error loading data</div>;
    }
  
    if (!data || !data[0] ) {
      return <div>No data available</div>;
    }
  
  return (
    <div className={rtl}>
      <Distributors ar={ar} />
      <MainDistributors ar={ar} />
      <Congrat ar={ar}
      companycertificate={convertData?.companycertificate} 
      companycertificateimage={convertData?.companycertificateimage} 
      companycertificatedescription={convertData?.companycertificatedescription} 
      />
      <WhatsIcon  />

    </div>
  );
}
