import React, { useContext } from 'react'
import LanguageContext from '../../LanguageContext';
import Contact from './Contact';
import StillOnContact from './StillOnContact';
import Distributions from '../Globals/Distributors'
import WhatsIcon from '../ui/WhatsIcon';

export default function ContactUs() {
  const { ar,  rtl } =
  useContext(LanguageContext);
  return (
    <div className={rtl}>
      <Contact ar={ar}/>
      <StillOnContact ar={ar}/>
      <Distributions ar={ar}/>
      <WhatsIcon />

    </div>
  )
}
