import React from "react";
import { WhyUsIcons } from "../../assets/svg";
import whuUsImg from "../../assets/img/whyUs/Images.png";
import whyus from  '../../assets/img/whyUs/whyus.png'
import LightLabel from "../ui/LightLabel";
export default function WhyUs({ ar , whyustitle , whysudescription }) {
  return (
    <section className="h-scrren">
      <div className="ImageWhyUs h-[30vh] hidden md:block md:h-[50vh]"></div>
      <div className=" relative h-[100vh] md:h-[50vh]   flex justify-center items-center ">
        <div
          className={`bg-white ${
            ar ? "textAr" : "text"
          }  p-6 absolute top-10 md:-top-[200px] flex    flex-col-reverse  justify-center gap-8 md:flex-row`}
        >
          <div className="md:flex-1 flex justify-center md:items-start items-center gap-4 flex-col">
            <LightLabel title={ar ? "لماذا نحن" : "Why us"} />
            <h1 className="flex  boldAr  flex-col gap-3 max-w-[500px]  text-black-120 bold text-3xl text-center md:text-start   tracking-wider">
              {ar
                ? whyustitle
                : "We provide full range global Piping and fittings solution"}
            </h1>
            <p className="max-w-[500px] mediumAr text-[16px] text-gray-120 text-center md:text-start">
              {ar
                ? whysudescription
                : " Leverage agile frameworks to provide a robust synopsis forstrategy foster collaborative thinking to further the overall value proposition."}
            </p>
            <p className="max-w-[500px] text-[16px] text-gray-120">
              {ar
                ? ""
                : "  Organically grow the holistic world view of disruptive innovation via workplace diversity and empowerment."}
            </p>
            <div className="w-full flex justify-center gap-4 flex-col text-2xl items-start">
              <div className="flex boldAr   items-center justify-center gap-4 text-md bold">
                {WhyUsIcons}
                {ar ? "شحنة في الوقت المحدد" : "Consignment On Tim"}
              </div>
              <div className="flex boldAr   items-center justify-center gap-4 text-md bold">
                {WhyUsIcons}
                {ar
                  ? "عروض مرنة تغطي كافة أنحاء العراق"
                  : "Flexible Deals Covers All Iraq"}
              </div>
              <div className="flex boldAr   items-center justify-center gap-4 text-md bold">
                {WhyUsIcons}
                {ar ? "منتجات واسعة النطاق" : "Wide Range Products"}
              </div>
            </div>
          </div>
          <div className="flex-1  flex justify-center items-center">
            <img src={whuUsImg} alt="whyUs" className="w-[500px] pr-8 invisible md:visible   " />
            <img src={whyus} alt="factory" className="w-[500px] pr-8 block md:hidden" />
          </div>
          
        </div>
      </div>
    </section>
  );
}
