
import { useContext } from "react";
import LanguageContext from "../../LanguageContext";
import Congrat from "../Globals/Congrat";
import Distributions from "../Globals/Distributors";
import Media from "./Media";
import WhatsIcon from "../ui/WhatsIcon";

export default function MediaPage() {
  const { ar, rtl } =
    useContext(LanguageContext);

  return (
    <div className={rtl}>
      <Media ar={ar}/>
       <Congrat ar={ar} />
       <Distributions ar={ar}/>
       <WhatsIcon  />

    </div>
  );
}
