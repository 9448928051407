import { twMerge } from "tailwind-merge";
import { onlinecontent } from "../../api/BaseUrl";
//src={`${onlinecontent}assets/img/${companycertificateimage}`}
export default function Box({ title, imgPath, p , className , staticImage }) {
  console.log(imgPath)
  return (
    <div className={twMerge("flex flex-col  items-center  gap-[22px] md:w-80 lg:w-64 xl:w-80 mx-auto",className)}>
       <img src={imgPath} alt="Factory" /> 
      <h3 className="font-bold text-[20px] sm:text-[25px]">{title}</h3>
      {p && <p className="text-center lg:text-right">{p}</p>}
    </div>
  );
}
