import React from "react";
import LightLabel from "../ui/LightLabel";
import { clock, contactEmail, contactPhone } from "../../assets/svg/contact";
import Button from "../ui/Button";

export default function StillOnContact({ ar }) {
  return (
    <div className="flex justify-center items-center md:my-28 md:mt-[200px]">
      <div className="bg-blue-120 flex flex-col gap-4 justify-center items-center pt-20 w-full md:w-3/4">
      {/*       <div className="bg-blue-120 flex flex-col gap-4 justify-center items-center pt-20 w-full md:w-3/4 lg:w-1/2">
 */}
        <LightLabel title={ar ? "تواصل معنا" : "CONTACT"} />
        <h1 className="text-white font-bold md:text-4xl text-xl">
          {ar ? `ابق على تواصل معنا` : "Get in touch with us"}
        </h1>
        <p className="text-white w-3/4 text-center md:text-lg text-md mt-3">
          {ar
            ? "استفد من أطر العمل الرشيقة لتوفير ملخص قوي للاستراتيجية التي تعزز التفكير التعاوني لتعزيز القيمة الإجمالية."
            : "Leverage agile frameworks to provide a robust synopsis for strategy foster collaborative thinking to further the overall value."}
        </p>
        <div className="flex justify-center flex-wrap gap-10 w-full items-center mt-5">
          <div className="gap-4 flex justify-center items-center flex-col text-white text-xs md:text-lg">
            {contactPhone}
            <p>{ar ? "+964 78 000 999 88" : "+964 78 000 999 88"}</p>
          </div>
          <div className="gap-4 flex justify-center items-center flex-col text-white text-xs md:text-lg">
            {contactEmail} <p>info@brk.iq</p>
          </div>
          <div className="flex gap-4 relative w-full justify-center text-center items-center flex-col text-white text-xs md:text-lg md:w-fit">
            {clock} <p> بغداد - الزعفرانية</p>
          </div>
        </div>
        <div className="bg-none md:px-10 py-7 mt-3 flex flex-col gap-5 input-grid min-w-[80%] w-full">
          {/* adding min-w-[80%] to fix the input fields width on small screens */}
          <input
            type="text"
            placeholder={ar ? "اسمك الثلاثي " : "Your name"}
            className="border bg-blue-120 p-4 w-full"
          />
          <input
            type="email"
            placeholder={ar ? "البريد الالكتروني" : "Email"}
            className="border bg-blue-120 p-4 w-full"
          />
          <input
            type="number"
            placeholder={ar ? "رقم الهاتف" : "Phone Number"}
            className="border bg-blue-120 p-4 w-full"
          />
          <input
            type="text"
            placeholder={ar ? "المحافظة" : "Province"}
            className="border bg-blue-120 p-4 w-full"
          />
          <input
            type="text"
            placeholder={ar ? "اقرب نقطة دالة" : "Address"}
            className="border bg-blue-120 p-4 w-full"
          />
          <textarea
            name=""
            id=""
            className="border bg-blue-120 p-4 h-40 w-full"
            placeholder={ar ? "رسالتك" : "Your Message"}
          ></textarea>
        </div>
        <div className="text-center pb-20 font-bold mb-[250px] md:mb-0">
          <Button title={ar ? "ارسال" : "Submit"} />
        </div>
      </div>
    </div>
  );
}
