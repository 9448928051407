import React, { useContext } from "react";
import Partners from "./Partners";
import MainPartners from "./main";
import Distributions from "../Globals/Distributors";
import LanguageContext from "../../LanguageContext";
import WhatsIcon from "../ui/WhatsIcon";
import { onlineApi } from "../../api/BaseUrl";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";
const PartnerIndex = () => {
  const {  ar, rtl } =
  useContext(LanguageContext);

  async function getDistributors() {
    const res = await axios.get(
      `${onlineApi}distributers`
    );
    return res.data
  }

  const { data:homePageDistributers } = useQuery({
    queryKey: ["get-data-distributors"],
    queryFn: getDistributors,
  });


  return (
    <div className={rtl}>
      <Partners ar={ar}/>
      <MainPartners ar={ar}/>
      <Distributions data={homePageDistributers} ar={ar}/>
      <WhatsIcon  />

    </div>
  );
};

export default PartnerIndex;
