import MainLabel from "../../ui/MainLabel";
import MainHugeTitle from "../../ui/MainHugeTitle";

export default function Landing({ar}) {
  return (
    <section className="h-[50vh] landing-image relative">
      <div className=" h-full flex flex-col justify-center items-center">
        <div className="container">
          <MainLabel title={ar? ' الوسائط' :"Media "} />
          <MainHugeTitle title={ar? ' مشروع بدور بغداد التصميم الداخلي' :"Bdoor Baghdad Project Interaction Design "} />
        </div>
      </div>
    </section>
  );
}
