import React, { useState } from "react";
// import Paragraph from "../ui/Paragraph";
import { searchInput } from "../../assets/svg/Distributors";
import SingleDistributors from "./SingleDistributors";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { onlineApi } from "../../api/BaseUrl";

export default function MainDistributors({ ar }) {
  async function getDistributors() {
    const res = await axios.get(
      `${onlineApi}distributers`
    );
    return res.data
  }

  const { data } = useQuery({
    queryKey: ["get-data-distributors"],
    queryFn: getDistributors,
  });
  console.log(data);

  const [nameOfMap, setNameOfMap] = useState("basra");
  console.log(nameOfMap);

  const maps = {
    baghdad:
      "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3334.576729457257!2d44.353461584804386!3d33.30373478081623!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x15577fb31b354701%3A0x91de64e19c7497d7!2sNASS%20AL%20IRAQ%20HOLDING!5e0!3m2!1sar!2sus!4v1708164647099!5m2!1sar!2sus",
    basra:
      "https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d3334.3930853073175!2d44.34819502478214!3d33.30853748035877!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x15577e42c6f56a83%3A0x26cf54825537a0c0!2sGICTD-%20Global%20Intelligence%20For%20Consulting%2C%20Training%20%26%20Development!5e0!3m2!1sar!2sus!4v1719224574878!5m2!1sar!2sus",

    babil:
      "https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d4715.365626932865!2d44.35285600533878!3d33.31183425793038!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x15577f40ab2b266f%3A0xdd1f74be7695b7dc!2z2KjZj9mGINix2LbYpyDYudmE2YjYp9mGIC0g2KfZhNmF2YbYtdmI2LE!5e0!3m2!1sar!2sus!4v1719225480069!5m2!1sar!2sus",
  };

  return (
    <div className=" w-full h-[1500px] bg-gray-20">
      <div className="flex gap-4 w-full justify-center item-center md:flex-row flex-col">
        <div className="left  md:h-screen  pt-16   ">
          {/* <div className="py-3 flex justify-start">
            <Paragraph
              p={
                ar
                  ? "استفد من أطر العمل الرشيقة لتوفير ملخص قوي للاستراتيجية التي تعزز التفكير التعاوني لتعزيز عرض القيمة الشاملة."
                  : "Leverage agile frameworks to provide a robust synopsis for strategy foster collaborative thinking to further the overall value proposition."
              }
            />
  
          </div> */}
          <div class="map-responsive  ">
            <iframe
              src={maps[nameOfMap]}
              width="600"
              height="650"
              allowFullScreen=""
              title="BRK"
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
              className="p-2 bg-white"
            ></iframe>
          </div>
        </div>
        <div className="right h-full pt-16">
          <div className="flex justify-center items-center gap-2 ">
            <div className="flex w-full justify-center items-center bg-white gap-4 px-4 md:w-[350px]">
                {searchInput}
                <input
                  type="text"
                  className="border w-full h-[40px] border-none"
                  placeholder={ar? 'ابحث هنا ...':"search...."}
                />
            </div>
            <select name="location" id="" className=" mr-2 h-[40px] px-4">
              <option value="baghdad">بغداد</option>
              <option value="basra">البصرة</option>
              <option value="babil">بابل</option>
            </select>
          </div>

          <div className="h-screen pb-14 scroll">
          {data && data.map((item) => (
          <SingleDistributors
            setNameOfMap={setNameOfMap}
            location={item.gov}
            ar={ar}
            item={item}
            key={item.id}
          />
        ))}
           
          </div>
        </div>
      </div>
    </div>
  );
}
