import { useContext } from "react";
import LanguageContext from "../../LanguageContext";
import imgo from "../../assets/img/media/Photo.png";
import MainHugeTitle from "../ui/MainHugeTitle";
import MainLabel from "../ui/MainLabel";
import { Link } from "react-router-dom";


function Media() {
  const data = [
    {
      date: "2021-07-20",
      title:
        "BRK is now the official distributor of the world's leading brands in Iraq",
      titleAr: "مشروع بدور         بغداد         التصميم الداخلي",
      img: imgo,
    },
    {
      date: "2021-07-20",
      title:
        "BRK is now the official distributor of the world's leading brands in Iraq",
      titleAr: "مشروع بدور         بغداد         التصميم الداخلي",
      img: imgo,
    },
    {
      date: "2021-07-20",
      title:
        "BRK is now the official distributor of the world's leading brands in Iraq",
      titleAr: "مشروع بدور         بغداد         التصميم الداخلي",
      img: imgo,
    },
    {
      date: "2021-07-20",
      title:
        "BRK is now the official distributor of the world's leading brands in Iraq",
      titleAr: "مشروع بدور         بغداد         التصميم الداخلي",
      img: imgo,
    },
    {
      date: "2021-07-20",
      title:
        "BRK is now the official distributor of the world's leading brands in Iraq",
      titleAr: "مشروع بدور         بغداد         التصميم الداخلي",
      img: imgo,
    },
    {
      date: "2021-07-20",
      title:
        "BRK is now the official distributor of the world's leading brands in Iraq",
      titleAr: "مشروع بدور         بغداد         التصميم الداخلي",
      img: imgo,
    },
  ];
  const { ar,rtl } =
    useContext(LanguageContext);
  return (

    <div className={rtl}>
      <section className="h-[50vh] landing-image relative">
      <div className=" h-full flex flex-col justify-center items-center">
        <div className="container">
          <MainLabel title={ar? 'الاستثمار الصناعي' :"Industrial Investment"} />
          <MainHugeTitle title={ar? 'الوسائط ' :"Media"} />
        </div>
      </div>
    </section>
      <div className=" grid grid-cols-2 gap-5 w-[70%] mx-auto py-40">
        {data.map((item, index) => (
          <div className="  flex w-full  bg-[#EEF0F8]" key={index}>
            {index % 4 < 2 ? (
              <Link to='/singleMedia'>
              <div className="flex w-full">
              <div className=" w-1/2 ">
                <img src={imgo} alt="imageo" className="block w-full object-cover"  />
              </div>
                <div className="flex w-1/2  flex-col justify-between p-5">
                  <span className="text-gray-120">{item.date}</span>
                  <h3 className="text-[26px] bold boldAr text-black-120">{ar ? item?.titleAr : item.title}</h3>
                </div>
              </div>
              </Link>
              
            ) : (
             <Link to='/singleMedia'>
              <div className="w-full flex">
                <div className="flex flex-col w-1/2 justify-between p-5">
                  <span className="text-gray-120">{item.date}</span>
                  <h3 className="text-[26px]  bold boldAr text-black-120">{ar ? item?.titleAr : item.title}</h3>
                </div>
                <div className=" w-1/2 ">

                <img src={imgo} alt="imageo" className="block w-full object-cover" />
                </div>
              </div></Link>
            )}
          </div>
        ))}
      </div>
    
      
    </div>
  );
}

export default Media;
