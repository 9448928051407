import Landing from "./Landing";
import About from "../Globals/About";
import CompanyValues from "./CompanyValues";
import OurVision from "./OurVision";
import CompanyObjectives from "./CompanyObjectives";
import Distributions from "../Globals/Distributors";
import { useContext } from "react";
import LanguageContext from "../../LanguageContext";
import axios from "axios";
import { useQuery } from "@tanstack/react-query";
import { onlineApi } from "../../api/BaseUrl";
import WhatsIcon from "../ui/WhatsIcon";
export default function AboutPage() {
  const { ar, rtl } =
    useContext(LanguageContext);
    async function getAboutus() {
      const res = await axios.get(
        `${onlineApi}aboutus`
      );
      return res.data
    }
  
    const { data , isLoading, error } = useQuery({
      queryKey: ["get-data-aboutus"],
      queryFn: getAboutus,
    });
    async function getDistributors() {
      const res = await axios.get(
        `${onlineApi}distributers`
      );
      return res.data
    }
  
    const { data:aboutUsDistributers } = useQuery({
      queryKey: ["get-data-distributors"],
      queryFn: getDistributors,
    });
  

    console.log(data && data);
    if (isLoading) {
      return <div>Loading...</div>;
    }
  
    if (error) {
      return <div>Error loading data</div>;
    }
  
    if (!data || !data[0]) {
      return <div>No data available</div>;
    }
    console.log(data && data);
    console.log(data[0].vision);
const convertData = data[0]


  return (
    <div className={rtl}>
      <Landing ar={ar} />
      <About ar={ar} includeVision={false} aboutus={convertData.aboutus} />
      <div className="py-8 flex justify-center items-center">
      <CompanyValues ar={ar} />
        
      </div>
      <OurVision ar={ar} whoisus={convertData.whoisus} whoisus2={convertData.whoisus2} whoisus3={convertData.whoisus3} whoisus4={convertData.whoisus4} ourvision={convertData.ourvision} />
      <CompanyObjectives ar={ar} />
      <Distributions ar={ar} data={aboutUsDistributers}/>
      <WhatsIcon  />

    </div>
  );
}
