import React, { useContext } from "react";
import LanguageContext from "../../../LanguageContext";
import worker from '../../../assets/singleMedia/worker.jpg'
import tube10 from '../../../assets/singleMedia/tube10.png'
import tube from '../../../assets/singleMedia/tube.png'
import tube7 from '../../../assets/singleMedia/tube7.png'
import tube6 from '../../../assets/singleMedia/tube6.png'
import tube8 from '../../../assets/singleMedia/tube8.png'

const Single = () => {
  const { ar } = useContext(LanguageContext);
  return (
    <div className="flex items-center justify-center min-h-screen p-20">
    <div className="grid lg:grid-cols-2 grid-cols-1 gap-4 justify-center">
      <div className="flex bg-[#EEF0F8] flex-col justify-between p-9 w-[498px] lg:h-auto h-[350px]  ">
        <span className="text-gray-120">
          {ar ? "فبراير 2023" : "Feb 2023"}
        </span>
        <h3 className="text-[26px] bold boldAr text-black-120">
          {ar ? "مشروع بدور  بغداد التصميم الداخلي" : "Bdoor Baghdad Project Interaction Design"}
        </h3>
      </div>
      <img src={worker} alt="" className="h-auto w-[498px]" />
      <img src={tube10} alt="" className="h-auto w-[498px]" />
      <img src={tube} alt="" className="h-auto w-[498px]" />
      <img src={tube7} alt="" className="h-auto w-[498px]" />
      <img src={tube6} alt="" className="h-auto w-[498px]" />
      <img src={tube8} alt="" className="h-auto w-[498px]" />
      <img src={tube7} alt="" className="h-auto w-[498px]" />
    </div>
  </div>
  
  );
};

export default Single;
