import Footer from "./Footer";
import NavBar from "./NavBar";


const Layout = ({children}) =>{
    return(
        <>
        <NavBar />
        {children}
        <Footer/>
        </>
    )
}
export default Layout;