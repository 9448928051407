import React from "react";

import { onlinecontent } from "../../api/BaseUrl";
const Card = ({ item }) => {
  return (
    <>
      <div
        key={item.id}
        className="border-2 p-5 rounded-sm border-gray-200 w-80  mb-10 justify-self-center"
      >
        <div className="w-full p-5 flex justify-center items-center ">
          <img src={`${onlinecontent}/assets/img/${item.image}`} alt="" />
        </div>
        <p className="text-center mt-24 font-bold">{item.name} </p>
      </div>
    </>
  );
};

export default Card;
