import React from "react";
import CertifiPng from "../../assets/img/partners/certificate.png";
import Pattern from "../../assets/img/congrat/Pattern.png";
import { twMerge } from "tailwind-merge";
import { onlinecontent } from "../../api/BaseUrl";

export default function Congrat({ ar , className , companycertificate ,companycertificateimage, companycertificatedescription}) {
  console.log(companycertificateimage)

  return (
    <section
      className={twMerge('relative  flex flex-col  md:flex-row min-h-[150vh] md:min-h-[80vh] md:h-[100vh] bg-blue-120 ' , className)}
    >
     <div className="w-[100%] md:w-[40%] flex justify-center ">
  <div className={`absolute md:top-[400px] top-[250px] mx-auto ${ar ? 'md:left-[800px]' : 'md:left-[-250px]'} left-1/2 transform -translate-x-1/2 md:w-[400px] w-[300px] md:top-1/2 md:-translate-y-1/2 md:-right-32 z-40 border-[20px]`}>
    <img src={CertifiPng} alt="certificate" />
   
  </div>
</div>

      <div className="bg-gray-20 w-[100%] md:w-[60%] flex justify-center relative items-start container pt-80 md:py-80 py-10  md:pr-44 top-[400px] md:top-0 gap-10 flex-col">
        <div className="absolute z-10 w-full h-full  left-0 flex justify-center items-center ">
          <img src={Pattern} alt="" className="w-full " />
        </div>
        <div className="h-full z-20 flex flex-col justify-center gap-10 md:py-8">
          <div className="bg-gray-200 flex w-fit md:mx-1 mx-auto">
            <p className=" font-bold text-yellow-120 flex justify-center items-center bg-yellow-120">
              |
            </p>
            <p className="text-md bold boldAr  regular text-black-120 p-2 ">
              {ar ? "شهادات الجودة" : "ISO Certificates"}
            </p>
          </div>
          <h2
            className={`flex boldAr bold  flex-col gap-3  text-black-120 text-2xl md:text-5xl font-semibold tracking-wider ${
              ar ? "md:text-right boldAr" : "md:text-left ExtraBold"
            } text-center`}
          >
            {ar ? companycertificate : "Company Certificates"}
          </h2>
          <p
            className={`max-w-[500px] Medium mediumAr text-[16px] text-gray-120 text-center ${
              ar ? "md:text-right" : "md:text-left"
            }`}
          >
            {ar
              ? companycertificatedescription
              : "Sed ut perspiciatis, unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam eaque ipsa, quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt, explicabo. Nemo enim ipsam voluptatem quia voluptassit."}{" "}
          </p>
         
          <div
            className={`flex gap-4 md:gap-10 justify-center md:justify-start   `}
          >
            <img src={`${onlinecontent}assets/img/${companycertificateimage}`} alt="congratIcons" className="w-20 " />
            <img src={`${onlinecontent}assets/img/${companycertificateimage}`} alt="congratIcons" className="w-20 " />
            <img src={`${onlinecontent}assets/img/${companycertificateimage}`} alt="congratIcons" className="w-20 " />
            <img src={`${onlinecontent}assets/img/${companycertificateimage}`} alt="congratIcons" className="w-20 " />

        
          </div>
        </div>
      </div>
    </section>
  );
}
