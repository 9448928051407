import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import React from "react";
import { onlineApi } from "../../api/BaseUrl";

export default function Visiter({ ar  }) {
  async function companyDetails() {
    const res = await axios.get(
      `${onlineApi}companydetails`
    );
    console.log(res.data);
    return res.data
  }
  
  const { data } = useQuery({
    queryKey: ["get-data-companyDetails"],
    queryFn: companyDetails,
  });
  return (
    <section className="pb-10 mt-72    md:mt-2    ">
      <div className="container flex justify-center border-gray-400 flex-col  md:flex-row md:h-24 m-auto">
        <div className="border-y border-gray-400 h-full">
          <div className={`flex h-full px-6 justify-center ${ar? 'border-l' : 'border-r'}  gap-3 items-center`}>
            {/* <h1 className="Extrabold text-5xl">+180</h1> */}
            <h1 className="Extrabold text-5xl">{data && data[0].productsnum} </h1>

            <div className="bg-yellow-120 w-6 h-6 text-yellow-120 flex justify-center items-center p-2 ">
              .
            </div>
            <div>
              <p className="text-[#1C1F35] text-lg light">
                {ar ? "منتج " : "Certificated"}
              </p>
              <p className="text-[#1C1F35] light">{ar ? "مصنع" : "Product"}</p>
            </div>
          </div>
        </div>
        <div className="border-y border-gray-400 h-full">
          <div className={`flex h-full px-6 justify-center ${ar? 'border-r' : 'border-l'}   gap-3 items-center`}>
            {/* <h1 className="Extrabold text-5xl">+50</h1> */}
            <h1 className="Extrabold text-5xl">{data && data[0].partnersnum}</h1>

            <div className="bg-yellow-120 w-6 h-6 text-yellow-120 flex justify-center items-center p-2 ">
              .
            </div>
            <div>
              <p className="text-[#1C1F35] text-lg light">
                {ar ? "موزع" : "Provinces"}
              </p>
              <p className="text-[#1C1F35] light text-lg">
                {ar ? "حول العراق" : "Across All Iraq"}
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
