import React, { useEffect, useRef } from "react";
import tubes from "../../../assets/img/Product/tubes.png";
import singleProductPhoto from "../../../assets/img/Product/singleProductPhoto.png";
import tube from "../../../assets/img/Product/tube.png";

import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import { FreeMode, Navigation, Pagination } from "swiper/modules";
// import SingleProduct from "../../../components/ui/SingleProduct";

const SingleProductPage = () => {
  const swiperRef = useRef(null);

  useEffect(() => {
    window.scrollTo({ top: 0 });
  }, []);
  const products = [
    {
      img: singleProductPhoto,
      title: "Wiring – Mixing PVC Conduit Schedules",
      script: "$13",
    },
    {
      img: singleProductPhoto,
      title: "Wiring – Mixing PVC Conduit Schedules",
      script: "$13",
    },
    {
      img: singleProductPhoto,
      title: "Wiring – Mixing PVC Conduit Schedules",
      script: "$13",
    },
    {
      img: singleProductPhoto,
      title: "Wiring – Mixing PVC Conduit Schedules",
      script: "$13",
    },
    {
      img: singleProductPhoto,
      title: "Wiring – Mixing PVC Conduit Schedules",
      script: "$13",
    },
    {
      img: singleProductPhoto,
      title: "Wiring – Mixing PVC Conduit Schedules",
      script: "$13",
    },
    {
      img: singleProductPhoto,
      title: "Wiring – Mixing PVC Conduit Schedules",
      script: "$13",
    },
    {
      img: singleProductPhoto,
      title: "Wiring – Mixing PVC Conduit Schedules",
      script: "$13",
    },
    {
      img: singleProductPhoto,
      title: "Wiring – Mixing PVC Conduit Schedules",
      script: "$13",
    }
  ];
  return (
    <div className="p-20 staticFont">
      <div className="flex flex-col ">
        <h1 className="w-fit font-semibold">
          Home / Products / PPR Products /{" "}
          <span className="border-b-2 border-gray-120">
            Georg Fischer PVC Pipe, 2m long x 32mm OD, 2.4mm Wall Thickness
          </span>
        </h1>
        <div className="flex justify-between gap-32 mt-10 ">
          <div className="flex flex-col">
            <div className="border-2 border-gray-100">
              <img src={tubes} alt="" />
            </div>
            <div className="flex py-5">
              <img src={tubes} alt="" className="w-10" />
              <img src={tube} alt="" className="w-10" />
              <img src={tube} alt="" className="w-10" />
              <img src={tube} alt="" className="w-10" />
              <img src={tube} alt="" className="w-10" />
              <img src={tube} alt="" className="w-10" />
              <span class="material-icons text-sm">keyboard_arrow_right</span>
            </div>
          </div>
          <div>
            <h1 className="border-b-2 font-bold border-gray-100">PVC Pipe</h1>
            <div className="w-3/4">
              <h1 className="my-5 font-bold">Features</h1>
              <p className="text-[#666]">
                Georg Fischer PVC Pipe, 2m long x 32mm OD, 2.4mm Wall Thickness
                2m long x 32mm OD, 2.4mm Wall Thickness
              </p>
              {/* <ul className="list-disc mx-4 marker:text-amber-500 ">
                <li>Georg Fischer PVC Pipe, 2m long x 32mm OD, 2.4mm Wall Thickness 2m long x 32mm OD, 2.4mm Wall Thickness</li>
                <li>Feature text goes here</li>
                <li>Feature text goes here</li>
                <li>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit , sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                  Leo a diam sollicitudin tempor id eu nisl nunc. Maecenas sed
                  enim ut sem viverra. Su
                </li>
              </ul> */}
            </div>
          </div>
        </div>
      </div>
      {/* <div>
        <h1 className="font-bold mt-6 mb-3">Additional information</h1>
        <div class="flex flex-col ">
          <div class="overflow-x-auto sm:mx-0.5 lg:mx-0.5">
            <div class="py-2 inline-block min-w-full">
              <div class="overflow-hidden">
                <table class="min-w-full border divide-y-2 ">
                  <tbody>
                    <tr class="bg-gray-100 border-b">
                      <td class="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                        Brand
                      </td>
                      <td class="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                        Georg Fischer
                      </td>
                    </tr>
                    <tr class="bg-white border-b">
                      <td class="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                        Length
                      </td>
                      <td class="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                        1.5m
                      </td>
                    </tr>
                    <tr class="bg-gray-100 border-b">
                      <td class="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                        Thickness{" "}
                      </td>
                      <td class="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                        22mm{" "}
                      </td>
                    </tr>
                    <tr class="bg-white border-b">
                      <td class="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                        Outer Diameter
                      </td>
                      <td class="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                        23mm{" "}
                      </td>
                    </tr>
                    <tr class="bg-gray-100 border-b">
                      <td class="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                        Brand
                      </td>
                      <td class="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                        Georg Fischer
                      </td>
                    </tr>
                    <tr class="bg-white border-b">
                      <td class="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                        Length
                      </td>
                      <td class="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                        1.5m
                      </td>
                    </tr>
                    <tr class="bg-gray-100 border-b">
                      <td class="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                        Thickness{" "}
                      </td>
                      <td class="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                        22mm{" "}
                      </td>
                    </tr>
                    <tr class="bg-white border-b">
                      <td class="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                        Outer Diameter
                      </td>
                      <td class="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                        23mm{" "}
                      </td>
                    </tr>
                    <tr class="bg-gray-100 border-b">
                      <td class="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                        Brand
                      </td>
                      <td class="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                        Georg Fischer
                      </td>
                    </tr>
                   
                    
              
                  
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div> */}{" "}
      <div className="mt-10">
        <h1 className="font-bold">This product goes well with</h1>
        <div className="relative m-4">
          <Swiper
            onSwiper={(swiper) => {
              swiperRef.current = swiper;
            }}
            freeMode={true}
            spaceBetween={10}
            slidesPerView={6}
            navigation={false}
            modules={[FreeMode, Pagination, Navigation]}
            className="mySwiper px-10"
          >
            {products.map((product, index) => (
              <SwiperSlide key={index} className="flex flex-col ">
                <div className="border-gray-300 border text-start p-2">
                  <img src={product.img} alt="" />
                  <div>
                    <p className="text-xs w-[152px] text-[#333333]">
                    {product.title}
                    </p>
                    <p className="font-bold text-xs">{product.script}</p>
                  </div>
                </div>
               
              </SwiperSlide>
            ))}
          </Swiper>
          <button
            className="absolute top-1/2 z-10 left-6 transform -translate-y-1/2 hoverArrow    text-white px-[14px] py-4 rounded-full"
            onClick={() => swiperRef.current?.slideNext()}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="14"
              height="35"
              viewBox="0 0 14 35"
              fill="none"
            >
              <g clip-path="url(#clip0_1342_6387)">
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M0.709351 19.0426L12.3187 34.517C12.6958 34.8941 13.3006 34.8941 13.6776 34.517C14.0547 34.1399 14.0547 33.5352 13.6776 33.1581L2.34563 17.8261L13.6776 2.49407C14.0547 2.117 14.0547 1.51226 13.6776 1.13518C13.4927 0.950203 13.2437 0.850594 13.0018 0.850594C12.7599 0.850594 12.5108 0.943085 12.3259 1.13518L0.716465 16.6095C0.453058 16.9606 0.309597 17.3872 0.307287 17.8261C0.304979 18.2646 0.44618 18.6919 0.709351 19.0426Z"
                  fill="#666666"
                />
              </g>
              <defs>
                <clipPath id="clip0_1342_6387">
                  <rect
                    width="14"
                    height="34"
                    fill="white"
                    transform="matrix(-1 0 0 -1 14 34.7998)"
                  />
                </clipPath>
              </defs>
            </svg>
          </button>
          <button
            className="absolute top-1/2 z-10 right-6 -rotate-180 transform -translate-y-1/2 hoverArrow   text-white px-[14px] py-4 rounded-full"
            onClick={() => swiperRef.current?.slidePrev()}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="14"
              height="35"
              viewBox="0 0 14 35"
              fill="none"
            >
              <g clip-path="url(#clip0_1342_6387)">
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M0.709351 19.0426L12.3187 34.517C12.6958 34.8941 13.3006 34.8941 13.6776 34.517C14.0547 34.1399 14.0547 33.5352 13.6776 33.1581L2.34563 17.8261L13.6776 2.49407C14.0547 2.117 14.0547 1.51226 13.6776 1.13518C13.4927 0.950203 13.2437 0.850594 13.0018 0.850594C12.7599 0.850594 12.5108 0.943085 12.3259 1.13518L0.716465 16.6095C0.453058 16.9606 0.309597 17.3872 0.307287 17.8261C0.304979 18.2646 0.44618 18.6919 0.709351 19.0426Z"
                  fill="#666666"
                />
              </g>
              <defs>
                <clipPath id="clip0_1342_6387">
                  <rect
                    width="14"
                    height="34"
                    fill="white"
                    transform="matrix(-1 0 0 -1 14 34.7998)"
                  />
                </clipPath>
              </defs>
            </svg>
          </button>
        </div>
      </div>
    </div>
  );
};

export default SingleProductPage;
