import React, { useState, useEffect, useContext, useRef } from 'react';
import { Swiper, SwiperSlide } from "swiper/react";
import SingleProduct from "../ui/SingleProduct";

import LanguageContext from "../../LanguageContext";

import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/pagination";
import "swiper/css/navigation";

import { FreeMode, Pagination, Navigation } from "swiper/modules";
import { useMediaQuery } from '@mui/material';
import LightLabel from '../ui/LightLabel';
import { Link } from 'react-router-dom';
import HugeTitle from '../ui/HugeTitle';
import axios from 'axios';
import { onlineApi } from '../../api/BaseUrl';
import { useQuery } from '@tanstack/react-query';

const Products = () => {
  const { ar } = useContext(LanguageContext);

  const matches = useMediaQuery('(min-width:768px)'); 
  const [layout, setLayout] = useState(matches ? 'grid' : 'swiper');
  const swiperRef = useRef(null);

 
  async function getProductsInfo() {
    const res = await axios.get(
      `${onlineApi}product_info_despage`
    );
    console.log(res, 'res');
    return res.data
  }
  
  const { data } = useQuery({
    queryKey: ["product_info_despage"],
    queryFn: getProductsInfo,
  });
const info = data && data[0]
  const products = [
    {
      img: info?.item1image,
      title: ar ? info?.item1 : "UPVC Products",
      script: ar
        ? info?.item1des
        : "Westlake Pipe & Fittings' AquaSpring™ C900 Certa-Lok® RJ PVC Pipe is manufactured to meet the standards of today’s municipal needs for water and wastewater pressure applications."
    },
    {
      img: info?.item2image,
      title: ar ? info?.item2 : "PRO Products",
      script: ar
        ? info?.item2des
        : "Westlake Pipe & Fittings' AquaSpring™ C900 Certa-Lok® RJ PVC Pipe is manufactured to meet the standards of today’s municipal needs for water and wastewater pressure applications."
    },
    {
      img: info?.item3image,
      title: ar ? info?.item3 : "PRR Products",
      script: ar
        ? info?.item3des
        : "Westlake Pipe & Fittings' AquaSpring™ C900 Certa-Lok® RJ PVC Pipe is manufactured to meet the standards of today’s municipal needs for water and wastewater pressure applications."
    },
    {
      img: info?.item4image,
      title: ar ? info?.item4 : "Accessories",
      script: ar
        ? info?.item4des
        : "Westlake Pipe & Fittings' AquaSpring™ C900 Certa-Lok® RJ PVC Pipe is manufactured to meet the standards of today’s municipal needs for water and wastewater pressure applications."
    },
  ];
  useEffect(() => {
    setLayout(matches ? 'grid' : 'swiper');
  }, [matches]);

  return (
    <>
    
      {layout === 'swiper' && (
        <section
        id="products"
        className={`  GradientColorProducts flex justify-center py-10`}
      >
        <div className="container  gap-4 text-center ">
          <LightLabel title={ar ? "المنتجات" : "Products"} />
          <HugeTitle
            title={ar ? info?.name : "Our High-Quality Products"}
          />
       
        <div className="relative m-4">
          <Swiper
            onSwiper={(swiper) => {
              swiperRef.current = swiper;
            }}
            freeMode={true}
           spaceBetween={30}
            navigation={false}
            modules={[FreeMode, Pagination, Navigation]}
            className="mySwiper px-10"
          >
            {products.map((product, index) => (
              <SwiperSlide key={index} className="flex flex-col">
                <SingleProduct
                  img={product.img}
                  title={product.title}
                  script={product.script}
                />
              </SwiperSlide>
            ))}
          </Swiper>
          <button
            className="absolute top-1/2 z-10 left-6 transform -translate-y-1/2 hoverArrow   hover:bg-yellow-120 bg-[#091242] text-white px-[14px] py-4 rounded-full"
            onClick={() => swiperRef.current?.slideNext()}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="15"
              height="12"
              viewBox="0 0 15 12"
              fill="none"
            >
              <path
                d="M13.5352 6H1.53516M1.53516 6L6.53516 1M1.53516 6L6.53516 11"
                stroke="white"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </button>
          <button
            className="absolute top-1/2 z-10 right-6 -rotate-180 transform -translate-y-1/2 hoverArrow  hover:bg-yellow-120 bg-[#091242] text-white px-[14px] py-4 rounded-full"
            onClick={() => swiperRef.current?.slidePrev()}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="15"
              height="12"
              viewBox="0 15 12"
              fill="none"
            >
              <path
                d="M13.5352 6H1.53516M1.53516 6L6.53516 1M1.53516 6L6.53516 11"
                stroke="white"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </button>
        </div>
        <Link to="/productsByCatigory">
        <div className='w-full flex justify-center'>

             <button
               className={`"Regular mediumAr mt-2 bg-blue-120 w-fit px-8 py-2 text-white rounded-[0px]"`}
               >
               {ar ? "عرض جميع المنتجات" : "Show All Products"}
             </button>
                 </div>
           </Link>
           </div>
           </section>
      )}
      {layout === 'grid' && (
         <section
         id="products"
         className={`  GradientColorProducts flex justify-center py-28  `}
       >
         <div className="container  flex gap-4 justify-center items-center flex-col">
           <LightLabel title={ar ? "المنتجات" : "Products"} />
           <HugeTitle
             title={ar ? info?.name : "Our High-Quality Products"}
           />
           
           <div className="flex flex-wrap justify-center my-8 gap-8">
          {products.map((product, index) => (
            <SingleProduct
              key={index}
              img={product.img}
              title={product.title}
              script={product.script}
            />
          ))}
        </div>
          
         </div>
       </section>
       
      )}
    </>
  );
};

export default Products;
