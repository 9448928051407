import React, { useRef } from "react";
import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/pagination";
import "swiper/css/navigation";

import { FreeMode, Pagination, Navigation } from "swiper/modules";
// import axios from "axios";
// import { useQuery } from "@tanstack/react-query";
import SectionTitle from "../ui/Section-title";
import LightLabel from "../ui/LightLabel";
// import { BaseUrl } from "../../api/BaseUrl";
import { onlinecontent } from "../../api/BaseUrl";

export default function Distributions({ ar, data}) {
  const swiperRef = useRef(null);

  
  // });

  return (
    <section id="Distributions " className="py-24 mt-36   md:mt-14">
      <div className="w-full flex justify-center items-center flex-col gap-4">
        <LightLabel title={ar ? "الوكلاء" : "Distributions"} />
        <SectionTitle title={ar ? " الوكلاء لدينا" : "Our Distributions"} />
      </div>
      <div className="relative mt-4">
        <Swiper
          onSwiper={(swiper) => {
            swiperRef.current = swiper;
          }}
          freeMode={true}
          breakpoints={{
            816: {
              slidesPerView: 2,
              spaceBetween: 20,
            },
            // when window width is >= 768px
            1190: {
              slidesPerView: 3,
              spaceBetween: 30,
            },
            // when window width is >= 1024px
            1250: {
              slidesPerView: 4,
              spaceBetween: 30,
            },
          }}
          navigation={false}
          modules={[FreeMode, Pagination, Navigation]}
          className="mySwiper"
        >
          {data?.map((item) => (
            <SwiperSlide key={item.id} className="flex flex-col">
        <img src={`${onlinecontent}/assets/img/${item.image}`} alt="" className="w-20  " />
        </SwiperSlide>
          ))}
        </Swiper>
        <button
          className="absolute hoverArrow top-1/2 z-10 left-6 transform -translate-y-1/2 md:bg-[#E0E0E0] hover:bg-yellow-120 bg-[#091242] px-[14px] py-4 rounded-full group"
          onClick={() => swiperRef.current?.slideNext()}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="15"
            height="12"
            viewBox="0 0 15 12"
            fill="none"
          >
            <path
              d="M13.5352 6H1.53516M1.53516 6L6.53516 1M1.53516 6L6.53516 11"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              stroke="white"

            />
          </svg>
        </button>

      <button
          className="absolute hoverArrow top-1/2 z-10 right-6 -rotate-180 transform -translate-y-1/2 md:bg-[#E0E0E0] hover:bg-yellow-120 bg-[#091242]  px-[14px] py-4 rounded-full"
          onClick={() => swiperRef.current?.slidePrev()}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="15"
            height="12"
            viewBox="0 0 15 12"
            fill="none"
          >
            <path
              d="M13.5352 6H1.53516M1.53516 6L6.53516 1M1.53516 6L6.53516 11"
              stroke="white"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </button>  
      </div>
    </section>
  );
}
