import SectionTitle from "../ui/Section-title";
import LightLabel from "../ui/LightLabel";
import Box from "./Box";

import warehouseImg from "../../assets/about-page/cover.svg";
import up from "../../assets/about-page/up.svg";
import trackImg from "../../assets/about-page/track.svg";
import exhibitionImg from "../../assets/about-page/exhibition.svg";
import customerImg from "../../assets/about-page/customer.svg";
import boat from "../../assets/about-page/boat.svg";

import axios from "axios";
import { onlineApi } from "../../api/BaseUrl";
import { useQuery } from "@tanstack/react-query";

export default function CompanyObjectives({ ar }) {
  async function getProductsInfo() {
    const res = await axios.get(`${onlineApi}companygoals`);
    return res.data;
  }

  const { data } = useQuery({
    queryKey: ["companygoals"],
    queryFn: getProductsInfo,
  });
  console.log(data, "res");

  return (
    <div className="bg-[#F4F4F4] flex flex-col justify-center items-center py-20 px:8 md:px-36">
      <LightLabel title={ar ? "من نحن" : "About us"} />
      <SectionTitle title={ar ? "أهداف الشركة" : "Company Objectives"} />
      <div className="flex lg:grid lg:grid-cols-3 flex-col flex-wrap justify-center gap-16 items-center my-10 md:my-16  lg:gap-48">
        {data?.map((item, index) => (
          <Box
            key={item?.id}
            title={ar ? item?.name : "Sea Transport Services"}
            imgPath={item?.icon}
            staticImage={index === 0 ? warehouseImg : index === 1 ?  up  :index === 2 ?  customerImg  :index === 3 ?  exhibitionImg  :index === 4 ?  trackImg  : index === 5 ?  boat :'' }
            p={
              ar
                ? item?.description
                : "Following the quality of our service thus having gained trust of our many clients."
            }
            className="lg:items-start"
          />
        ))}
      </div>
    </div>
  );
}
