import React from "react";
import Card from "./card";
import axios from "axios";
import { onlineApi } from "../../api/BaseUrl";
import { useQuery } from "@tanstack/react-query";
const MainPartners = ({ar}) => {

  async function getPartners() {
    const res = await axios.get(
      `${onlineApi}partners`
    );
    console.log(res.data);
    return res.data
  }
  
  const { data } = useQuery({
    queryKey: ["get-data-getPartners"],
    queryFn: getPartners,
  });
  return (
    <div className="responsive-grid py-10 px-40 ">
       {data?.map(item => (
     
      <Card item={item} key={item.id} />
    ))}
    </div>
  );
};

export default MainPartners;
