import React from "react";
import { onlinecontent } from "../../api/BaseUrl";

const ProductInfo3 = ({ item }) => {
  console.log(item);

  return (
    <div>
      <div className="p-5 flex justify-center gap-7 border border-gray-300 ">
        <div>
          <img
            src={`${onlinecontent}assets/img/${item.image}`}
            alt=""
            className="mb-4 w-56"
          />
        </div>
        <div className="flex flex-col gap-4 justify-start">
          <h4 className=" font-medium staticFont">{item?.material}</h4>

          <p className=" text-sm  text-gray-400 mb-2 staticFont w-80 ">
            {item?.description}
          </p>
        </div>
      </div>
      
    </div>
  );
};

export default ProductInfo3;
