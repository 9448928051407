import Home from "./Home";
import About from "../Globals/About";
import WhyUs from "./WhyUs";
import Visiter from "./Visiter";
import Partners from "../Globals/Partners";
import Congrat from "../Globals/Congrat";
import Distributors from "../Globals/Distributors";
import LanguageContext from "../../LanguageContext";
import { useContext } from "react";
import Products from "./Products";
import { onlineApi } from "../../api/BaseUrl";
import axios from "axios";
import { useQuery } from "@tanstack/react-query";
import WhatsIcon from "../ui/WhatsIcon";

export default function HomePage() {
  const { ar, rtl } = useContext(LanguageContext);

  async function getHomepagedatas() {
    const res = await axios.get(`${onlineApi}homepagedata`);
    console.log(res.data);
    return res.data;
  }

  const { data, isLoading, error } = useQuery({
    queryKey: ["get-data-Homepage"],
    queryFn: getHomepagedatas,
  });

  async function getAboutus() {
    const res = await axios.get(`${onlineApi}aboutus`);
    console.log(res.data);
    return res.data;
  }

  const { data: aboutusData, isLoading: aboutusLoading, error: aboutusError } = useQuery({
    queryKey: ["get-data-aboutus"],
    queryFn: getAboutus,
  });

  
  async function getPartners() {
    const res = await axios.get(
      `${onlineApi}partners`
    );
    console.log(res.data);
    return res.data
  }
  
  const { data:homepagePartners } = useQuery({
    queryKey: ["get-data-getPartners"],
    queryFn: getPartners,
  });

  async function getDistributors() {
    const res = await axios.get(
      `${onlineApi}distributers`
    );
    return res.data
  }

  const { data:homePageDistributers } = useQuery({
    queryKey: ["get-data-distributors"],
    queryFn: getDistributors,
  });

  if (isLoading || aboutusLoading) {
    return <div>Loading...</div>;
  }

  if (error || aboutusError) {
    return <div>Error loading data</div>;
  }

  if (!data || !data[0] || !aboutusData || !aboutusData[0]) {
    return <div>No data available</div>;
  }

  const convertData = data[0];
  const convertAboutusData = aboutusData[0];

  return (
    <div className={rtl}>
      <Home ar={ar} />
      <About 
        includeVision={true} 
        ar={ar} 
        aboutus={convertAboutusData.aboutus} 
        vision={convertData.vision} 
      />
      <Products ar={ar} />
      <WhyUs 
        ar={ar} 
        whyustitle={convertData.whyustitle} 
        whysudescription={convertData.whysudescription} 
      />
      <Visiter ar={ar} />
      <Partners ar={ar} data={homepagePartners} />
      <Congrat 
        ar={ar} 
        className='mt-24' 
        companycertificate={convertData.companycertificate} 
        companycertificateimage={convertData.companycertificateimage} 
        companycertificatedescription={convertData.companycertificatedescription} 
      />
      <Distributors ar={ar} data={homePageDistributers} />
      <WhatsIcon />

    </div>
  );
}
