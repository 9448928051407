import React from "react";
import mainImage from "../../assets/img/about/image.png";
import imaging from "../../assets/img/about/imaging.png";
import LightLabel from "../ui/LightLabel";
import Paragraph from "../ui/Paragraph";
import SubTitle from "../ui/SubTitle";
import Button from "../ui/Button";
import SectionTitle from "../ui/Section-title";

export default function About({ includeVision, ar , vision , aboutus}) {
  console.log(aboutus);
  return (
    <section id="about" className="py-10 my-24 lg:py-0">
      <div className="container lg:min-h-[60vh] gap-8 py-10 flex flex-col-reverse lg:flex-row lg:items-center lg:justify-center">
        <div className="flex justify-center items-center lg:w-[50vw]">
          <div className={`relative flex ${ar? 'lg:ml-24':'lg:mr-24'}`}>
            <img
              src={imaging}
              className="w-[300px] md:w-[450px] lg:min-w-[600px]"
              alt="Factory"
            />
            <img
              src={mainImage}
              alt="Factory 2"
              className={`hidden lg:block absolute min-w-[200px] -bottom-16 ${ar? '-left-16':'-right-16' } border-[16px] border-white`}
            />
          </div>
        </div>
        <div className="flex flex-col justify-center items-center gap-4 text-center lg:items-start lg:text-start">
          <LightLabel title={ar ? "نبذة عن الشركة" : "Company overview"} />
          <SectionTitle title={ar ? "من نحن" : "About Us"} />
          <Paragraph
            main={false}
            p={
              ar
                ? aboutus
                : "Sed ut perspiciatis, unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam eaque ipsa, quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt, explicabo. Nemo enim ipsam voluptatem quia voluptassit."
            }
          />
          {includeVision && 
          <div>
            <h4 className="text-black-120 boldAr text-[16px]">
            {ar ? "الرؤية" : "Vision"}
          </h4>
          <SubTitle
            title={
              ar
                ? vision
                : "Establishing presence locally, and launch towards markets globalism"
            }
          />
          <Button title={ar ? "استكشف المزيد" : "Explore More"} className='mt-7' />
          </div>
          }
          
        </div>
      </div>
    </section>
  );
}
