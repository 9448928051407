import React from "react";
import { twMerge } from "tailwind-merge";

export default function Button({title , ar , className}) {
  return (
    <button className={twMerge('mediumAr Medium  bg-yellow-120 w-fit px-8 py-2 rounded-[0px]', className)}>
      {title}
    </button>
  );
}
