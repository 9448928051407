export const Bdoor = (
  <svg
    width="128"
    height="62"
    viewBox="0 0 128 62"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0 61.1618V50.6698H4.14812C4.9103 50.6698 5.54601 50.7842 6.05525 51.013C6.56449 51.2418 6.94726 51.5595 7.20357 51.9659C7.45987 52.3689 7.58803 52.8334 7.58803 53.3594C7.58803 53.7692 7.50709 54.1295 7.34521 54.4403C7.18333 54.7477 6.96075 55.0005 6.67746 55.1986C6.39755 55.3932 6.07717 55.5316 5.71631 55.6135V55.716C6.11089 55.7331 6.48018 55.8458 6.82417 56.0541C7.17153 56.2625 7.45313 56.5545 7.66897 56.9302C7.8848 57.3024 7.99272 57.7464 7.99272 58.2622C7.99272 58.8189 7.85614 59.3158 7.58297 59.753C7.31317 60.1867 6.91354 60.53 6.38406 60.7827C5.85458 61.0355 5.20201 61.1618 4.42635 61.1618H0ZM2.19041 59.3483H3.97613C4.58654 59.3483 5.03171 59.2304 5.31162 58.9948C5.59153 58.7557 5.73149 58.4381 5.73149 58.0419C5.73149 57.7516 5.66235 57.4954 5.52408 57.2734C5.38581 57.0514 5.18852 56.8772 4.93222 56.7509C4.67928 56.6245 4.37745 56.5613 4.02671 56.5613H2.19041V59.3483ZM2.19041 55.0602H3.81425C4.1144 55.0602 4.38082 55.0073 4.61352 54.9014C4.84959 54.7921 5.03508 54.6384 5.16998 54.4403C5.30825 54.2423 5.37738 54.0049 5.37738 53.7282C5.37738 53.3491 5.24417 53.0435 4.97775 52.8112C4.71469 52.579 4.34035 52.4628 3.85472 52.4628H2.19041V55.0602Z"
      fill="#1C1F35"
    />
    <path
      d="M13.2331 61.1618H9.56046V50.6698H13.2634C14.3055 50.6698 15.2026 50.8798 15.9546 51.2999C16.7067 51.7166 17.2851 52.316 17.6898 53.0981C18.0978 53.8802 18.3019 54.816 18.3019 55.9055C18.3019 56.9985 18.0978 57.9377 17.6898 58.7232C17.2851 59.5088 16.7033 60.1116 15.9445 60.5317C15.1891 60.9518 14.2853 61.1618 13.2331 61.1618ZM11.7509 59.2612H13.142C13.7895 59.2612 14.3342 59.145 14.776 58.9128C15.2211 58.6771 15.555 58.3134 15.7776 57.8216C16.0035 57.3263 16.1165 56.6877 16.1165 55.9055C16.1165 55.1303 16.0035 54.4967 15.7776 54.0049C15.555 53.5131 15.2228 53.151 14.781 52.9188C14.3392 52.6866 13.7946 52.5704 13.1471 52.5704H11.7509V59.2612Z"
      fill="#1C1F35"
    />
    <path
      d="M29.5873 55.9158C29.5873 57.0599 29.3732 58.0333 28.9449 58.8359C28.52 59.6386 27.9399 60.2516 27.2047 60.6751C26.4729 61.0952 25.65 61.3053 24.7361 61.3053C23.8154 61.3053 22.9891 61.0935 22.2573 60.67C21.5255 60.2465 20.9471 59.6334 20.5222 58.8308C20.0972 58.0282 19.8848 57.0565 19.8848 55.9158C19.8848 54.7716 20.0972 53.7983 20.5222 52.9956C20.9471 52.193 21.5255 51.5817 22.2573 51.1616C22.9891 50.7381 23.8154 50.5263 24.7361 50.5263C25.65 50.5263 26.4729 50.7381 27.2047 51.1616C27.9399 51.5817 28.52 52.193 28.9449 52.9956C29.3732 53.7983 29.5873 54.7716 29.5873 55.9158ZM27.3666 55.9158C27.3666 55.1747 27.257 54.5496 27.0378 54.0408C26.8219 53.5319 26.5167 53.1459 26.1221 52.8829C25.7276 52.62 25.2655 52.4885 24.7361 52.4885C24.2066 52.4885 23.7446 52.62 23.35 52.8829C22.9554 53.1459 22.6485 53.5319 22.4293 54.0408C22.2135 54.5496 22.1055 55.1747 22.1055 55.9158C22.1055 56.6569 22.2135 57.2819 22.4293 57.7908C22.6485 58.2997 22.9554 58.6857 23.35 58.9487C23.7446 59.2116 24.2066 59.3431 24.7361 59.3431C25.2655 59.3431 25.7276 59.2116 26.1221 58.9487C26.5167 58.6857 26.8219 58.2997 27.0378 57.7908C27.257 57.2819 27.3666 56.6569 27.3666 55.9158Z"
      fill="#1C1F35"
    />
    <path
      d="M40.8728 55.9158C40.8728 57.0599 40.6587 58.0333 40.2304 58.8359C39.8054 59.6386 39.2254 60.2516 38.4902 60.6751C37.7583 61.0952 36.9355 61.3053 36.0215 61.3053C35.1008 61.3053 34.2746 61.0935 33.5428 60.67C32.811 60.2465 32.2326 59.6334 31.8076 58.8308C31.3827 58.0282 31.1702 57.0565 31.1702 55.9158C31.1702 54.7716 31.3827 53.7983 31.8076 52.9956C32.2326 52.193 32.811 51.5817 33.5428 51.1616C34.2746 50.7381 35.1008 50.5263 36.0215 50.5263C36.9355 50.5263 37.7583 50.7381 38.4902 51.1616C39.2254 51.5817 39.8054 52.193 40.2304 52.9956C40.6587 53.7983 40.8728 54.7716 40.8728 55.9158ZM38.652 55.9158C38.652 55.1747 38.5424 54.5496 38.3232 54.0408C38.1074 53.5319 37.8022 53.1459 37.4076 52.8829C37.013 52.62 36.551 52.4885 36.0215 52.4885C35.4921 52.4885 35.03 52.62 34.6355 52.8829C34.2409 53.1459 33.934 53.5319 33.7148 54.0408C33.4989 54.5496 33.391 55.1747 33.391 55.9158C33.391 56.6569 33.4989 57.2819 33.7148 57.7908C33.934 58.2997 34.2409 58.6857 34.6355 58.9487C35.03 59.2116 35.4921 59.3431 36.0215 59.3431C36.551 59.3431 37.013 59.2116 37.4076 58.9487C37.8022 58.6857 38.1074 58.2997 38.3232 57.7908C38.5424 57.2819 38.652 56.6569 38.652 55.9158Z"
      fill="#1C1F35"
    />
    <path
      d="M42.6378 61.1618V50.6698H46.7253C47.5077 50.6698 48.1754 50.8115 48.7285 51.095C49.2849 51.375 49.7082 51.7729 49.9982 52.2887C50.2916 52.801 50.4383 53.4038 50.4383 54.0971C50.4383 54.7938 50.2899 55.3932 49.9932 55.8953C49.6964 56.3939 49.2664 56.7765 48.7032 57.0429C48.1434 57.3093 47.4655 57.4425 46.6696 57.4425H43.9329V55.6596H46.3155C46.7337 55.6596 47.081 55.6016 47.3576 55.4855C47.6341 55.3693 47.8398 55.1951 47.9747 54.9629C48.113 54.7307 48.1822 54.4421 48.1822 54.0971C48.1822 53.7487 48.113 53.455 47.9747 53.2159C47.8398 52.9769 47.6324 52.7958 47.3525 52.6729C47.076 52.5465 46.7269 52.4833 46.3054 52.4833H44.8282V61.1618H42.6378ZM48.2327 56.3871L50.8076 61.1618H48.3896L45.8703 56.3871H48.2327Z"
      fill="#1C1F35"
    />
    <path
      d="M55.5682 61.1618V50.6698H59.7163C60.4785 50.6698 61.1142 50.7842 61.6234 51.013C62.1327 51.2418 62.5154 51.5595 62.7718 51.9659C63.0281 52.3689 63.1562 52.8334 63.1562 53.3594C63.1562 53.7692 63.0753 54.1295 62.9134 54.4403C62.7515 54.7477 62.5289 55.0005 62.2457 55.1986C61.9657 55.3932 61.6454 55.5316 61.2845 55.6135V55.716C61.6791 55.7331 62.0484 55.8458 62.3924 56.0541C62.7397 56.2625 63.0213 56.5545 63.2372 56.9302C63.453 57.3024 63.5609 57.7464 63.5609 58.2622C63.5609 58.8189 63.4243 59.3158 63.1512 59.753C62.8814 60.1867 62.4817 60.53 61.9523 60.7827C61.4228 61.0355 60.7702 61.1618 59.9945 61.1618H55.5682ZM57.7586 59.3483H59.5443C60.1547 59.3483 60.5999 59.2304 60.8798 58.9948C61.1597 58.7557 61.2997 58.4381 61.2997 58.0419C61.2997 57.7516 61.2305 57.4954 61.0923 57.2734C60.954 57.0514 60.7567 56.8772 60.5004 56.7509C60.2475 56.6245 59.9456 56.5613 59.5949 56.5613H57.7586V59.3483ZM57.7586 55.0602H59.3824C59.6826 55.0602 59.949 55.0073 60.1817 54.9014C60.4178 54.7921 60.6033 54.6384 60.7382 54.4403C60.8764 54.2423 60.9456 54.0049 60.9456 53.7282C60.9456 53.3491 60.8124 53.0435 60.5459 52.8112C60.2829 52.579 59.9085 52.4628 59.4229 52.4628H57.7586V55.0602Z"
      fill="#1C1F35"
    />
    <path
      d="M66.9144 61.1618H64.5671L68.1436 50.6698H70.9664L74.5378 61.1618H72.1906L69.5955 53.0674H69.5145L66.9144 61.1618ZM66.7677 57.0377H72.312V58.7693H66.7677V57.0377Z"
      fill="#1C1F35"
    />
    <path
      d="M82.31 54.0612C82.2392 53.8119 82.1397 53.5916 82.0116 53.4004C81.8834 53.2057 81.7266 53.0418 81.5411 52.9086C81.359 52.7719 81.1499 52.6678 80.9138 52.596C80.6811 52.5243 80.4231 52.4885 80.1398 52.4885C79.6104 52.4885 79.145 52.6217 78.7436 52.8881C78.3457 53.1545 78.0354 53.5421 77.8128 54.051C77.5903 54.5565 77.479 55.1747 77.479 55.9055C77.479 56.6364 77.5886 57.258 77.8078 57.7703C78.027 58.2827 78.3373 58.6737 78.7386 58.9435C79.1399 59.2099 79.6137 59.3431 80.1601 59.3431C80.6558 59.3431 81.0791 59.2543 81.4298 59.0767C81.7839 58.8957 82.0537 58.6413 82.2392 58.3134C82.428 57.9855 82.5225 57.5979 82.5225 57.1505L82.9676 57.2171H80.2967V55.5469H84.632V56.8687C84.632 57.7908 84.4397 58.5832 84.0553 59.2458C83.6708 59.905 83.1413 60.4139 82.4668 60.7725C81.7923 61.1277 81.0201 61.3053 80.15 61.3053C79.1787 61.3053 78.3255 61.0884 77.5903 60.6546C76.8551 60.2175 76.2818 59.5976 75.8703 58.795C75.4622 57.9889 75.2582 57.0326 75.2582 55.926C75.2582 55.0756 75.3796 54.3174 75.6224 53.6514C75.8686 52.982 76.2126 52.415 76.6544 51.9505C77.0962 51.486 77.6105 51.1326 78.1973 50.8901C78.7841 50.6476 79.4198 50.5263 80.1044 50.5263C80.6912 50.5263 81.2376 50.6134 81.7434 50.7876C82.2493 50.9584 82.6978 51.2009 83.0891 51.5151C83.4836 51.8293 83.8057 52.2033 84.0553 52.637C84.3048 53.0674 84.465 53.5421 84.5358 54.0612H82.31Z"
      fill="#1C1F35"
    />
    <path
      d="M86.4197 61.1618V50.6698H88.6102V54.9988H93.0567V50.6698H95.2421V61.1618H93.0567V56.8277H88.6102V61.1618H86.4197Z"
      fill="#1C1F35"
    />
    <path
      d="M100.863 61.1618H97.1905V50.6698H100.893C101.936 50.6698 102.833 50.8798 103.585 51.2999C104.337 51.7166 104.915 52.316 105.32 53.0981C105.728 53.8802 105.932 54.816 105.932 55.9055C105.932 56.9985 105.728 57.9377 105.32 58.7232C104.915 59.5088 104.333 60.1116 103.575 60.5317C102.819 60.9518 101.915 61.1618 100.863 61.1618ZM99.3809 59.2612H100.772C101.42 59.2612 101.964 59.145 102.406 58.9128C102.851 58.6771 103.185 58.3134 103.408 57.8216C103.634 57.3263 103.747 56.6877 103.747 55.9055C103.747 55.1303 103.634 54.4967 103.408 54.0049C103.185 53.5131 102.853 53.151 102.411 52.9188C101.969 52.6866 101.425 52.5704 100.777 52.5704H99.3809V59.2612Z"
      fill="#1C1F35"
    />
    <path
      d="M108.898 61.1618H106.551L110.128 50.6698H112.95L116.522 61.1618H114.175L111.579 53.0674H111.499L108.898 61.1618ZM108.752 57.0377H114.296V58.7693H108.752V57.0377Z"
      fill="#1C1F35"
    />
    <path
      d="M121.584 61.1618H117.911V50.6698H121.614C122.656 50.6698 123.553 50.8798 124.305 51.2999C125.057 51.7166 125.636 52.316 126.041 53.0981C126.449 53.8802 126.653 54.816 126.653 55.9055C126.653 56.9985 126.449 57.9377 126.041 58.7232C125.636 59.5088 125.054 60.1116 124.295 60.5317C123.54 60.9518 122.636 61.1618 121.584 61.1618ZM120.102 59.2612H121.493C122.14 59.2612 122.685 59.145 123.127 58.9128C123.572 58.6771 123.906 58.3134 124.128 57.8216C124.354 57.3263 124.467 56.6877 124.467 55.9055C124.467 55.1303 124.354 54.4967 124.128 54.0049C123.906 53.5131 123.574 53.151 123.132 52.9188C122.69 52.6866 122.145 52.5704 121.498 52.5704H120.102V59.2612Z"
      fill="#1C1F35"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M18.8632 40.4211V0C8.32936 0.694806 0 9.47782 0 20.2105C0 30.9432 8.32936 39.726 18.8632 40.4211ZM41.0947 0C30.1848 0.694047 21.5579 9.46746 21.5579 20.1884V40.4211H41.0947V0ZM84.2105 20.2105C84.2105 31.3725 75.1616 40.4211 64 40.4211C52.8384 40.4211 43.7895 31.3725 43.7895 20.2105C43.7895 9.04852 52.8384 0 64 0C75.1616 0 84.2105 9.04852 84.2105 20.2105ZM62.6526 4.71579C62.6526 3.9717 63.2563 3.36842 64 3.36842C64.7437 3.36842 65.3474 3.9717 65.3474 4.71579V35.7053C65.3474 36.4493 64.7437 37.0526 64 37.0526C63.2563 37.0526 62.6526 36.4493 62.6526 35.7053V4.71579ZM86.9053 0H107.453C118.8 0 128 9.04852 128 20.2105V40.4211H108.822V4.71579C108.822 3.9717 108.209 3.36842 107.453 3.36842C106.696 3.36842 106.083 3.9717 106.083 4.71579V40.4211H86.9053V0Z"
      fill="#4091DD"
    />
  </svg>
);
export const searchInput =(
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M15.0259 13.8473L18.5948 17.4163L17.4163 18.5948L13.8474 15.0258C12.5642 16.0525 10.9367 16.6667 9.16675 16.6667C5.02675 16.6667 1.66675 13.3067 1.66675 9.16667C1.66675 5.02667 5.02675 1.66667 9.16675 1.66667C13.3067 1.66667 16.6667 5.02667 16.6667 9.16667C16.6667 10.9367 16.0526 12.5641 15.0259 13.8473ZM13.354 13.229C14.373 12.1788 15.0001 10.7463 15.0001 9.16667C15.0001 5.94375 12.3897 3.33334 9.16675 3.33334C5.94383 3.33334 3.33341 5.94375 3.33341 9.16667C3.33341 12.3896 5.94383 15 9.16675 15C10.7464 15 12.1789 14.3729 13.2291 13.3539L13.354 13.229Z" fill="#999EB8"/>
</svg>

)