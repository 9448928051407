import React, { useContext } from 'react'
import { Link } from 'react-router-dom'
import { whatsApp } from '../../assets/svg/contact'
import { twMerge } from 'tailwind-merge'
import LanguageContext from '../../LanguageContext'
const WhatsIcon = ({className }) => {
    const { ar } = useContext(LanguageContext);

  return (
   <div className='w-screen h-screen bg-transparent absolute'>
 <Link to='https://wa.me/9647800099988/' target='_blank'  >
        <div className={ twMerge(` bg-[#18BD49]  rounded-full w-[60px] h-[60px] flex justify-center items-center  fixed z-10 ${ar? 'left-7': 'right-7'} bottom-10 `, className)}>
            {whatsApp}
        </div>
        </Link>
   </div>
       
  )
}

export default WhatsIcon