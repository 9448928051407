import React from "react";
import { onlinecontent } from "../../api/BaseUrl";
// import { Bdoor } from "../../assets/svg/Distributors";

export default function SingleDistributors({setNameOfMap, location, item, ar }) {

  return (
    <div onClick={()=>setNameOfMap(location)} className="mt-4 bg-white hover:shadow-[0px_20px_30px_0px_rgba(0,0,0,0.10)] hover:border-r-4 hover:border-yellow-300">
      <div className="h-full  flex justify-start p-2 item-center gap-6">
        {/* {Bdoor} */}
        <img src={`${onlinecontent}/assets/img/${item.image}`} alt="" className="w-20  " />
        <div className="h-full text-md  flex flex-col justify-center items-start ">
          {/* <p className=" font-medium">مشروع بدور بغداد</p> */}
          <p className=" font-medium"> {item.name} </p>

          <p className="text-gray-120">{item.gov}</p>
        </div>
      </div>
      <div className="flex border-t mt-3 border-gray-500 justify-center items-start p-2 gap-4">
        <div className="flex text-md justify-center  items-start flex-col">
          <p>
            {ar ? "الهاتف:" : "phone:"}{" "}
            <span className={`text-gray-120 ${ar? 'mr-4':'ml-4'}`}>{item.phone}</span>
          </p>
          <p>
            {ar ? " الموقع: " : "Website:"}{" "}
            <span className={`text-gray-120 ${ar? 'mr-4':'ml-4'}`}> {item.website}</span>
          </p>
        </div>
        <div className="max-w-[270px] flex">
          <p>{ar ? "العنوان:" : "Address:"} </p>
          <p className={`text-gray-120  ${ar? 'mr-4':'ml-4'} min-w-[10px]`}>
            {item.address}
            {/* العراق, بغداد, شارع الاميرات. قرب برج بغداد 1234. 76 B. 123S */}
          </p>
        </div>
      </div>
    </div>
  );
}
