import React, { useContext } from "react";
import Landing from "./Landing";
import LanguageContext from "../../LanguageContext";
import PaginationButtons from "../../components/ui/PaginationButtons";

import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { onlineApi } from "../../api/BaseUrl";
import ProductInfo3 from "../../components/ui/ProductInfo3";
import { Link } from "react-router-dom";

const ProductsPageByCatigory = () => {
  const { ar, rtl } = useContext(LanguageContext);
 
async function getProducts() {
  const res = await axios.get(
    `${onlineApi}products`
  );
  return res.data
}

const { data } = useQuery({
  queryKey: ["products"],
  queryFn: getProducts,
});


  console.log(data);
  return (
    <div className={rtl} >
      <Landing ar={ar} />
      <div className="flex flex-col">

       

     <div className=" grid lg:grid-cols-2 grid-cols-1 lg:gap-7 gap-5 py-10 px-2 lg:p-32 mx-auto   ">
       {data?.map(item=>(
            <Link key={item.id} to='/singleProduct'>
              <ProductInfo3  item={item} />

            </Link>

           ))}
           
     </div>
           
        
      

        
          <PaginationButtons/>
      </div>
    </div>
  );
};

export default ProductsPageByCatigory;
