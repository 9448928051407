import LightLabel from "../ui/LightLabel";
import SectionTitle from "../ui/Section-title";
import Box2 from "./Box2";

import FactoryImg from "../../assets/about-page/factory1.svg";
import SystemImg from "../../assets/about-page/system.svg";
import StrengthImg from "../../assets/about-page/strength.svg";
import Star from "../../assets/about-page/star.svg";


export default function CompanyValues({ ar }) {
  return (
    <section className="flex justify-center py-10 xl:py-0">
      <div className=" flex flex-col gap-5 items-center">
        <LightLabel title={ar ? "من نحن" : "About us"} />
        <SectionTitle title={ar ? "قيم الشركة" : "Company values"} />
        <div className="lg:flex grid grid-cols-2 gap-8 lg:gap-4 justify-center my-8 lg:flex-row ">
          <Box2 title={ar ? "الجودة" : "Factory"} imgPath={FactoryImg} className='lg:items-center' />
          <Box2 title={ar ? "الابتكار" : "System"} imgPath={SystemImg} className='lg:items-center' />
          <Box2 title={ar ? "العملاء" : "Strength"} imgPath={StrengthImg} className='lg:items-center' />
          <Box2 title={ar ? "التميز" : "Plumbing"} imgPath={Star} className='lg:items-center' />
        </div>
      </div>
    </section>
  );
}
