import { Link, useLocation } from "react-router-dom";
import { arabicLogoText, logo, logoText } from "../assets/svg";
import { Emails, Phones } from "../assets/svg/contact";
import { facebook, instagram, linkedin } from "../assets/svg/socialMedia";

import burgerMenuIcon from "../assets/menu.svg";
import { useContext, useState } from "react";
import LanguageContext from "../LanguageContext";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { onlineApi } from "../api/BaseUrl";

const NavBar = () => {
  const [IsMenuOpened, setIsMenuOpened] = useState(false);
  const {  toggleLanguage, ar, light } =
    useContext(LanguageContext);
  function toggleMenu() {
    setIsMenuOpened((prev) => !prev);
  }
  // connect to admin panel 
  async function getCompanyInfo() {
    const res = await axios.get(
      `${onlineApi}companyinfo`
    );
    console.log(res.data);
    return res.data
  }
  
  const { data } = useQuery({
    queryKey: ["get-data-companyInfo"],
    queryFn: getCompanyInfo,
  });

  const location = useLocation()
  const isActive = (path) => location.pathname === path;
  return (<>
   {data?.map(item=>(
         <header
      className={`bg-blue-120 ${
        ar && "rtl"
      } h-[80px] flex flex-col justify-center items-center`}
    >
      
      <div className="container relative flex justify-between  items-center">
        <Link to="/">
          <div className="flex gap-3 w-[200px] sm:w-[300px]">
            {logo} {ar ? arabicLogoText : logoText}
          </div>
        </Link>

        <div className="lg:hidden" onClick={toggleMenu}>
          <img src={burgerMenuIcon} alt="More" />
        </div>

        <div className="hidden justify-center items-center gap-4 lg:flex">
          <div className="flex justify-center items-center gap-4">
            {Emails}
            <div className={`text-white  mediumAr text-md`}>
              <Link href="">
                <p>{ar ? "البريد الالكتروني" : "Email"}</p>
                {/* <p>info@brk.iq</p> */}
                <p>{item?.email }</p>

              </Link>
            </div>
          </div>

          <div className={`flex justify-center ${light} items-center gap-4`}>
            {Phones}
            <div className="text-white mediumAr text-md">
              <p>{ar ? "اتصل بنا" : "Call Us"}</p>

              {/* <p className="staticFont ltr">+964 78 000 999 88</p> */}
              <p className="staticFont ltr">{item.phonenum} </p>

            </div>
            <button
              onClick={toggleLanguage}
              className="text-white w-16 h-10 Regular bg-gray-800 rounded-md"
            >
              {ar ? "English" : "العربية"}
            </button>
          </div>
        </div>
      </div>
      <nav
      className={`navbar-bg w-[100%] flex justify-center items-center absolute top-20 z-10 ${
        !IsMenuOpened ? "hidden md:flex" : ""
      }`}
    >
      <div
        className={`container h-full flex justify-between items-center gap-4 ${
          IsMenuOpened ? "flex-col" : ""
        }`}
      >
        <ul
          className={`flex gap-4  ${
            IsMenuOpened ? "flex-col right-4 p-4 absolute bg-[#faba20b8]" : ""
          }`}
        >
          <li className={`text-white boldAr text-md py-4 ${isActive("/") ? "border-b border-white" : ""}`}>
            <Link to="/">{ar ? "الرئيسية" : "Home"}</Link>
          </li>
          <span className="text-white text-opacity-60 py-4 hidden md:inline">|</span>
          <li className={`text-white boldAr text-md py-4 ${isActive("/aboutus") ? "border-b border-white" : ""}`}>
            <Link to="aboutus">{ar ? "من نحن" : "About"}</Link>
          </li>
          <span className="text-white text-opacity-60 py-4 hidden md:inline">|</span>
          <li className={`text-white boldAr text-md py-4 ${isActive("/products") ? "border-b border-white" : ""}`}>
            <Link to="products">{ar ? "المنتجات" : "Products"}</Link>
          </li>
          <span className="text-white text-opacity-60 py-4 hidden md:inline">|</span>
          <li className={`text-white boldAr text-md py-4 ${isActive("/partners") ? "border-b border-white" : ""}`}>
            <Link to="partners">{ar ? "الشركاء" : "Partners"}</Link>
          </li>
         
          <span className="text-white text-opacity-60 py-4 hidden md:inline">|</span>
          <li className={`text-white boldAr text-md py-4 ${isActive("/our-distributors") ? "border-b border-white" : ""}`}>
            <Link to="our-distributors">
              {ar ? "الوكلاء" : "Distributers"}
            </Link>
          </li>
        </ul>
        {ar ? (
          <div
            className={`flex gap-10 h-full justify-center items-center ${
              ar && "ltr"
            } ${IsMenuOpened ? "absolute left-4" : ""}`}
          >
            <Link to="contact">
              <button className="bg-yellow-120 w-full h-full flex justify-center items-center m-0 p-0">
                <p className="text-md font-medium p-4">
                  {ar ? "تواصل معنا" : "Contact Us"}
                </p>
              </button>
            </Link>
            <div className="flex gap-5 py-4">
             
              <Link to={item.linkedin}>{linkedin}</Link>
              <Link to={item.facebook}>{facebook}</Link>
              <Link to={item.instagram}>{instagram}</Link>

            </div>
          </div>
        ) : (
          <div
            className={`flex gap-10 h-full justify-center items-center ${
              ar && "ltr"
            } ${IsMenuOpened ? "absolute left-4" : ""}`}
          >
            <div className="flex gap-5 py-4">
              {linkedin}
              {facebook}
              {instagram}
            </div>
            <Link to="contact">
              <button className="bg-yellow-120 w-full h-full flex justify-center items-center m-0 p-0">
                <p className="text-md font-medium p-4">
                  {ar ? "تواصل معنا" : "Contact Us"}
                </p>
              </button>
            </Link>
          </div>
        )}
      </div>
    </nav>
    </header>
    ))}
  </>
   
   
  );
};
export default NavBar;
